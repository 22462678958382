import React, { useCallback, useContext, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import loadable from '@loadable/component';
import { setDefaultPlayer, setDefaultVjsTech } from '~/modules/preferences';
import { fetchStream, STREAMSHOTS_BITRATE_THRESHOLD } from '~/modules/streams';
import { preferences } from '~/services/storage';
import ErrorDisplay from './ErrorDisplay';
import { AUDIO } from './playerTechs';
import BlurContext from '../ContentBlur/BlurContext';

/**
 * max retries before prompting the user to reload the page
 */
const MAX_RETRIES = 5;
/**
 * Amount of seconds we'll wait before retrying
 */
const RETRY_AFTER = 10;
const OvenPlayerContainer = loadable(() => import('../OvenPlayer'));

const STREAM_AUTOPLAY_THRESHOLD = 4;

interface StreamPlayerProps {
  stream: Stream & { bitrate?: number };
  playbackKey?: string;
  defaultPlayer: string;
  defaultVjsTech: string;
  multiSize: number;
}

const StreamPlayer:React.FC<StreamPlayerProps> = ({
  stream,
  playbackKey = null,
  defaultPlayer,
  defaultVjsTech,
  multiSize,
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const params = new URLSearchParams(location.search);

  // default player options
  const playingRecording = useSelector(state => state.watchUI.playingRecordings[stream.username] || null);
  const autoplayPreference = useSelector(state => state.preferences.autoplay);
  const enableDVR = useSelector(state => state.preferences.enableDVR);

  const playerUrl = params.get('player');
  const techUrl = params.get('tech');

  // per-instance player selection
  const [player, setPlayer] = useState(playerUrl || defaultPlayer);
  const [vjsTech, setVjsTech] = useState(techUrl || defaultVjsTech);

  // error handling
  const [errored, setErrored] = useState(false);
  const [retries, setRetries] = useState(0);
  const [nextRetry, setNextRetry] = useState(0);
  const [autoplay, setAutoplay] = useState(false);
  const [startMuted, setStartMuted] = useState(false);

  const isVisible = useContext(BlurContext);

  useEffect(() => {
    const isSmallMultistream = multiSize < STREAM_AUTOPLAY_THRESHOLD;
    const auto = isVisible && isSmallMultistream && (autoplayPreference !== false);
    setAutoplay(auto);
    setStartMuted(auto && multiSize > 1);
  }, []);

  const setDefault = useCallback((playerId, tech) => {
    dispatch(setDefaultPlayer(playerId));

    if (tech) {
      dispatch(setDefaultVjsTech(tech));
    }

    if (__CLIENT__) {
      preferences.setItem('defaultPlayer', playerId);
      if (tech) {
        preferences.setItem('defaultVjsTech', tech);
      }
      preferences.serializeAndPersist();
    }
  }, [dispatch]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (stream.username) {
        dispatch(fetchStream(stream.username));
      }
    }, 60000);
    return () => clearInterval(interval);
  }, [stream.username, dispatch]);
 
  // /**
  //  * @type {typeof OvenPlayerContainer}
  //  */
  // let Player = OvenPlayerContainer;

  const highBitrate = (stream.bitrate && stream.bitrate > STREAMSHOTS_BITRATE_THRESHOLD);

  useEffect(() => {
    if (errored) {
      const firstRetry = retries === 0;
      const waitTime = RETRY_AFTER * 1000;
      setNextRetry(Date.now() + waitTime);
  
      if (retries < MAX_RETRIES) {
        const timeout = setTimeout(() => {
          setErrored(false);
          setRetries(retries + 1);
        }, waitTime);

        return () => {
          clearTimeout(timeout);
        };
      }
    }
  }, [errored]);

  const updatePlayerState = useCallback((newState) => {
    setErrored(() => {
      if (newState == 'playing') {
        setRetries(0);
      }

      return newState === 'error';
    });
  }, []);

  return (
    <>
      {
        errored && (
          <ErrorDisplay
            stream={stream}
            willRetry={retries < MAX_RETRIES}
            retryAfter={RETRY_AFTER * 1000}
            setDefault={setDefault}
            setPlayer={setPlayer}
            setVjsTech={setVjsTech}
            nextRetry={nextRetry}
            vjsTech={vjsTech}
          />
        )
      }
      <OvenPlayerContainer
        key={`${stream.id}`}
        autoplay={autoplay}
        startMuted={startMuted}
        stream={stream}
        recordingId={playingRecording}
        setDefault={setDefault}
        setPlayer={setPlayer}
        setVjsTech={setVjsTech}
        defaultPlayer={defaultPlayer}
        defaultVjsTech={defaultVjsTech}
        vjsTech={highBitrate ? AUDIO : vjsTech}
        onError={() => setErrored(true)}
        playbackKey={playbackKey}
        highBitrate={highBitrate}
        enableDVR={enableDVR}
        updatePlayerState={updatePlayerState}
        retries={retries}
      />
    </>
  );
}

export default StreamPlayer;
