import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Field } from 'formik';

import Button from '~/components/Button';

import styles from './TextField.scss';

const cx = classNames.bind(styles);

interface TextFieldProps {
  name: string;
  error?: string;
  action?: {
    icon: string;
    function: () => void;
  };
  indicator?: {
    icon: string;
    text: string;
    color: string;
  };
  hoverableValue?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  withTooltip?: boolean;
  readOnly?: boolean;
  fullWidth?: boolean;
  children?: React.ReactNode;
}


/**
 * Reusable Field component for single line text inputs
 */
export default function TextField(props: TextFieldProps) {
  const [isHovered, setHovered] = useState(false);

  const {
    value,
    name,
    children = undefined,
    error = null,
    action = null,
    readOnly = false,
    hoverableValue = null,
    fullWidth = false,
    withTooltip = false,
    indicator: orgIndicator = null,
    className,
  } = props;

  const propsField = Object.assign({}, props);
  delete propsField.fullWidth;
  const hoverableSpread = hoverableValue ? {
    onMouseEnter: () => setHovered(true),
    onMouseLeave: () => setHovered(false),
  } : null;

  const valueSpread = hoverableValue ? {
    value: isHovered ? hoverableValue : 'Hover to show',
  } : null;

  let indicator = orgIndicator;

  if (error) {
    indicator = {
      icon: 'ion-alert',
      text: error,
    };
  }

  return (
    <div
      className={cx('TextField', {
        'TextField--indicator': indicator,
        [`TextField--indicator-${indicator && indicator.color}`]: indicator && indicator.color,
        'TextField--action': action,
        'TextField--hoverable': hoverableValue,
        'TextField--fullWidth': fullWidth,
        'TextField--withTooltip': withTooltip,
      },
        className
      )}
      {...hoverableSpread}
    >
      {children && <label htmlFor={name}>{children}</label>}
      <Field
        type="text"
        id={name}
        name={name}
        readOnly={readOnly}
        {...valueSpread}
        {...{ ...propsField, children: undefined }}
      />
      {indicator && (
        <div data-testid={`error-indicator-${name}`} className={cx('TextField__Indicator', {
          [`TextField__Indicator--${indicator.color}`]: indicator.color,
        })}
        >
          {indicator.icon && <i className={cx(['TextField__IndicatorIcon', indicator.icon])} />}
          {indicator.text && <span className={cx('TextField__IndicatorText')}>{indicator.text}</span>}
        </div>
      )}
      {action && (
        <Button
          className={cx('TextField__Action')}
          onClick={action.function}
          color="black"
        >
          <i className={cx(action.icon)} />
        </Button>
      )}
    </div>
  );
};

