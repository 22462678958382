import React from 'react';

import poster from './poster.png';

import styles from './index.scss';

interface Stream {
  username?: string;
  offline_image: {
    offline_image: {
      url: string|null;
    };
  };
}

interface StreamPosterProps {
  stream: Stream;
}

const defaultValue:Stream = {
  offline_image: {
    offline_image: {
      url: null,
    },
  },
}

const StreamPoster:React.FC<StreamPosterProps> = ({ stream = defaultValue }) => {
  const { offline_image } = stream;
  const { url } = 'offline_image' in offline_image ? offline_image.offline_image : offline_image;

  return (
    <div style={{ backgroundImage: `url(${url || poster})` }} className={styles.StreamPoster} />
  );
}

export default StreamPoster;
