import React from 'react';
import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';

import FormattedMessage from '~/components/common/FormattedMessage';

import styles from './Checkbox.scss';

const cx = classNames.bind(styles);


interface CheckboxProps {
  name: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  children: React.ReactNode;
  disabled?: boolean;
  className?: string;
  forPremium?: boolean;
}

const Checkbox:React.FC<CheckboxProps> = ({
  name, checked, onChange, children, disabled = false, className = '', forPremium = false,
}) => (
  <div className={cx('Checkbox', { 'Checkbox--disabled': disabled }, className)}>
    <label className={cx('Checkbox__Label')}>
      <span className={cx('Checkbox__Text')}>{children}</span>

      <input
        name={name}
        disabled={disabled}
        onChange={onChange}
        checked={checked}
        className={cx('Checkbox__Input')}
        type="checkbox"
      />
      <div className={cx('Checkbox__Toggle')} />
    </label>
    {disabled && (
      <Link className={cx('Checkbox__Premium')} to="/account/patreon">
        <FormattedMessage id="Checkbox_PremiumOnly" defaultMessage="This is a feature for Premium users." />
      </Link>
    )}
  </div>
);

export default Checkbox;
