/**
 * An input to let users make comments on things
 */

import { Formik, Form, Field } from 'formik';
import React, { useRef } from 'react';
import Autocomplete, { EmoticonAutocomplete } from '~/components/Chat/Autocomplete';
import DescriptionEditor from '~/components/DescriptionEditor';

import Config from '~/config';

import styles from './CommentBox.scss';

const matchers = [
  EmoticonAutocomplete,
];

const CommentBox = ({
  currentUser: { avatar },
  currentUser,
  sending,
  reply_to: replyTo,
  sendComment
}) => {
  const ref = useRef();

  return (
    <Formik
      initialValues={{ comment: '', comment_hidden: '' }}
      onSubmit={async (values, actions) => {
        if (values.comment.trim() === '') {
          actions.resetForm();
          return;
        }
        await sendComment(values.comment, replyTo, values.comment_hidden);
        actions.resetForm();
      }}
    >
      {({ isSubmitting, values, setFieldValue, submitForm }) => {
        const focus = () => {
          const input = ref.current;

          if (input) input.focus();
        };

        const setMessage = (value) => {
          if (typeof value === 'string') {
            setFieldValue('comment', value);
          } else {
            setFieldValue('comment', value(values.comment));
          }
        };

        const onKeyDown = (e) => {
          if ((e.key === 'Enter' || e.keyCode === 13) && e.ctrlKey) {
            submitForm();
          }
        };

        return (
          <div className={styles.CommentBox__Wrapper}>
            <Autocomplete matchers={matchers} message={values.comment} setMessage={setMessage} focusInput={focus} roomName={currentUser.username} />
            <Form className={styles.CommentBox}>
              <img className={styles.CommentBox__Avatar} src={`${Config.avatarsHost}/avatars/${currentUser.id}`} alt={`${currentUser.username}'s avatar`}/>
              <DescriptionEditor setFieldValue={setFieldValue} onKeyDown={onKeyDown} previewDisabled small markdown tags={['a', 'i', 'b']} fieldProps={{ name: 'comment' }} />
              <Field type="text" name="comment_hidden" className={styles.CommentBox__Honey} />
              <div className={styles.CommentBox__Send}>
                <button
                  disabled={isSubmitting}
                  type="submit"
                  className={styles.CommentBox__Button}
                >
                  {isSubmitting ? 'Sending...' : 'Send'}
                </button>
              </div>
            </Form>
          </div>
        );
    }}
    </Formik>
  );
};

export default CommentBox;
