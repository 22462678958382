import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames/bind';

import { followStream, fetchStream, getStreamByUsername } from '~/modules/streams';
import FormattedMessage from '~/components/common/FormattedMessage';

// eslint-disable-next-line import/no-cycle
import Button from '..';

import styles from './FollowButton.scss';

const cx = classNames.bind(styles);

interface FollowButtonProps {
  small?: boolean;
  username: string;
  className?: string;
}

const FollowButton:React.FC<FollowButtonProps> = ({
  small = false, className = null, username,
}) => {
  const dispatch = useDispatch();
  const { isSignedIn } = useSelector(state => state.currentUser);
  const stream = useSelector(state => getStreamByUsername(state, username));


  useEffect(() => {
    if (!stream) dispatch(fetchStream(username));
  }, []);

  const toggleFollow = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!isSignedIn) {
      window.location.href = '/login';
      return;
    }

    const isFollowing = stream.following !== undefined && stream.following.value;

    dispatch(followStream(stream.username, !isFollowing));
  }, [dispatch, isSignedIn, stream]);

  if (!stream) return null;

  const isFollowing = stream.following !== undefined && stream.following.value;

  return (
    <Button
      className={cx(className, { 'FollowButton--small': small })}
      color="black"
      onClick={e => toggleFollow(e)}
    >
      {isFollowing
        ? (
          <FormattedMessage
            id="FollowButton_Following"
            defaultMessage="Following"
          />
        )
        : (
          <FormattedMessage
            id="FollowButton_Follow"
            defaultMessage="Follow"
          />
        )}
    </Button>
  );
};

export default FollowButton;
