import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import { FormattedMessage } from 'react-intl';

import { favoriteImage } from '~/modules/gallery';

import Button from '..';

import styles from './LikeButton.scss';

const cx = classNames.bind(styles);

interface Props {
  image: {
    id: number,
    favorite: boolean,
    favorites_count: number,
  },
  size?: 'small' | 'tiny' | null,
}

/**
 * Connected component building upon Button, used for favoriting an image
 */
const LikeButton: React.FC<Props> = ({
  image,
  size = 'small',
}) => {
  const dispatch = useDispatch();
  const isSignedIn = useSelector(state => state.currentUser.isSignedIn);
  const [isSubmitting, setSubmitting] = useState(false);

  const onFavorite = () => {
    if (!isSignedIn || isSubmitting) return;

    setSubmitting(true);
    dispatch(favoriteImage(image.id, !image.favorite))
      .then(() => setSubmitting(false));
  };

  return (
    <Button
      className={cx('LikeButton', {
        'LikeButton--active': image.favorite,
      })}
      color="flatBlue"
      size={size}
      onClick={onFavorite}
      disabled={isSubmitting}
    >
      {image.favorites_count}
      {' '}
      {image.favorite ? <FormattedMessage id="LikeButton_Liked" defaultMessage="Liked" /> : <FormattedMessage id="LikeButton_Like" defaultMessage="Like" />}
    </Button>
  );
};


export default LikeButton;
