import React, { useEffect, useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import htmlParser from 'react-markdown/plugins/html-parser';
import './index.css';

const plugins = [gfm];

function Markdown({ children, ...props }) {
  return (
    <ReactMarkdown linkTarget="_blank" plugins={plugins} {...props}>
      {children}
    </ReactMarkdown>
  );
}

const showSpoiler = (e) => {
  const spoiler = e.currentTarget;
  spoiler.setAttribute('aria-expanded', true);
};

const tagsSpoiler = (text) => {
  const spoiler = /\|\|(.*?)\|\|/gm;
  const result = text.replace(spoiler, '<span aria-expanded="false">$1</span>');
  return result;
};

const elementsAllowed = [
  'a',
  'b',
  'i',
  's',
  'p',
  'em',
  'img',
  'span',
  'strong',
];

const allowAttrbs = ['src', 'href', 'alt', 'aria-expanded'];

const createElement = (node, index, data, children) => {
  const elementProps = {
    key: index,
  };

  if (node.attribs) {
    Object.entries(node.attribs).forEach(([key, value]) => {
      if (allowAttrbs.some(attr => attr === key)) {
        elementProps[key] = value;
      }
    });
  }

  children = children || [];
  const allChildren = data != null ? [data].concat(children) : children;
  return React.createElement.apply(
    null, [node.name, elementProps].concat(allChildren),
  );
};

const processDefaultNode = (node, children, index) => {
  if (node.type === 'text') {
    return node.data;
  } if (node.type === 'comment') {
    return false;
  }
  return createElement(node, index, node.data, children);
};

const regExp = /(\s+)(\>)/gm;
const regExpInput = /\<input(.*?)>/gmi;

const cleanExp = value => (value || '').replace(regExp, '>').replace(regExpInput, '');

const parseHtml = htmlParser({
  isValidNode: node => {
    return elementsAllowed.some(name => name === node.name)
  },
  processingInstructions: [
    {
      shouldProcessNode: node => true,
      processNode: processDefaultNode,
    },
  ],
});

export function ReactMarkdownWithHtml({ children, ...props }) {
  const render = useMemo(() => tagsSpoiler(cleanExp(children)), [children]);

  useEffect(() => {
    const spoilers = document.querySelectorAll('span[aria-expanded="false"]');
    if (spoilers.length) {
      spoilers.forEach((spoiler) => {
        spoiler.addEventListener('click', showSpoiler);
      });
    }
    return () => {
      if (spoilers.length) spoilers.forEach(spoiler => spoiler.removeEventListener('click', showSpoiler));
    };
  }, [render]);

  return (
    <ReactMarkdown astPlugins={[parseHtml]} plugins={plugins} linkTarget="_blank" {...props}>
      {render}
    </ReactMarkdown>
  );
}

export default Markdown;
