/* node_modules */
import React from 'react';
import classNames from 'classnames/bind';

/* style imports */
import styles from './Loading.scss';

const cx = classNames.bind(styles);

interface LoadingProps {
  message?: string | React.ReactNode;
  className?: string;
  size?: string;
  icon?: string;
  color?: string;
}

const Loading:React.FC<LoadingProps> = ({
  message = null, className = null, size = null, icon = null, color = '#fff',
}) => (
  <div
    className={cx('Loading', className, {
      [`Loading--${size}`]: size,
    })}
  >
    <div className={cx('Loading__Icon')} style={{ backgroundImage: icon ? `url(${icon})` : undefined, backgroundColor: color, }} />
    {message && <p>{message}</p>}
  </div>
);

export default Loading;
