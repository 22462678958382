import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames/bind';
import { setSidePaneWidth } from '~/modules/chat';

import styles from './SidePane.scss';

export const SIDE_PANE_WIDTH = 320;

const cx = classNames.bind(styles);

interface CollapsibleProps {
  title: string;
  children: React.ReactNode;
  startOpen?: boolean;
}

export const Collapsible:React.FC<CollapsibleProps> = ({ title, children, startOpen = true }) => {
  const [open, setOpen] = useState(startOpen);

  return (
    <div className={cx('Collapsible', { 'Collapsible--Open': open })}>
      <button type="button" onClick={() => setOpen(!open)} className={styles.Collapsible__Title}>
        {title}
      </button>
      <div className={styles.Collapsible__Content}>
        { children }
      </div>
    </div>
  );
};

interface SidePaneProps extends CollapsibleProps {
  className?: string;
  isOpen: boolean;
}

const SidePane:React.FC<SidePaneProps> = ({
  title = null, children, isOpen, className = '',
}) => {
  const ref = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpen) {
      const pane = ref.current;

      if (pane) {
        dispatch(setSidePaneWidth(pane.offsetWidth));
      }
    }
  }, [isOpen, dispatch]);


  if (!isOpen) return null;

  return (
    <div ref={ref} className={`${styles.SidePane} ${className}`}>
      { title && <h2 className={styles.SidePane__Title}>{ title }</h2> }

      <div className={styles.SidePane__Content}>
        { children }
      </div>
    </div>
  );
};

export default SidePane;
