/**
 * Avatar component
 */

import React, { useCallback } from 'react';
import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';

import { getCurrentUser } from '~/modules/currentUser';
import config from '~/config';

import memoFreezeImg, { frozenAvatars } from './freezing';

import styles from './index.scss';

const cx = classNames.bind(styles);

const DEFAULT_AVATAR = `${config.avatarsHost}/avatars/default`;

interface User {
  id: number;
  username: string;
  avatar: {
    url: string;
  }
}

interface AvatarProps {
  user?: User|null;
  userId?: number|null;
  username?: string|null;
  controlled?: boolean;
  hovering?: boolean;
  className?: string;
  size?: string | undefined;
  previewImg?: string;
}

interface DefaultAvatarProps {
  className?: string | undefined;
  size?: string;
}

/**
 * Avatar compoonent, allows freezing gif avatars
 * currently it has no way to tell if an avatar is actually a gif or not so it just assumes it's a gif
 */
const Avatar: React.FC<AvatarProps> = ({
  user = null, userId = null, username = null, controlled = false, hovering = false, className = '', size = undefined, previewImg = '', ...props
}) => {
  const currentUser = useSelector(getCurrentUser);
  const isStatic = user?.avatar?.url && !user.avatar.url?.endsWith('.gif');

  const src = (() => {
    const isMyAvatar = currentUser && currentUser.username === username;

    if (previewImg) {
      return previewImg;
    }

    if (isMyAvatar) {
      return `${config.avatarsHost}/avatars/${currentUser.id}`;
    }

    if (userId) {
      return `${config.avatarsHost}/avatars/${userId}`;
    }

    return `${config.api}/avatars/${username}`;
  })();

  const frozenUrl = frozenAvatars[src];

  const freeze = useCallback((e) => {
    memoFreezeImg(e.target);
  }, []);
  return (
    <div
      className={cx('Avatar', className, { 'Avatar--Uncontrolled': !controlled, 'Avatar--Controlled': controlled, 'Avatar--Hovering': hovering })}
      style={{
        // @ts-ignore
        '--size': size || undefined,
      }}
      {...props}
    >
      {/* frozen img */}
      <img crossOrigin="anonymous" loading="lazy" className={cx('Avatar__Image', 'Avatar__Image--Frozen')} alt={`${username}'s avatar`} src={frozenUrl || (src)} />
      {/* normal img */}
      <img crossOrigin="anonymous" loading="lazy" className={cx('Avatar__Image', 'Avatar__Image--NotFrozen')} onLoad={isStatic ? undefined : freeze} alt={`${username}'s avatar`} src={src} />
    </div>
  );
};

export const DefaultAvatar:React.FC<DefaultAvatarProps> = ({ className = '', size = undefined, ...props }) => (
  <div
    className={cx('Avatar', className)}
    style={{
      // @ts-ignore
      '--size': size || undefined,
    }}
    {...props}
  >
    <img crossOrigin="anonymous" loading="lazy" className={cx('Avatar__Image', 'Avatar__Image--Frozen')} alt="Default avatar" src={DEFAULT_AVATAR} />
  </div>
);


export default Avatar;
