import React from 'react';
import { Navigate } from 'react-router-dom';

interface ErrorHandlerProps {
  statusCode: number|null;
}

const ErrorHandler:React.FC<ErrorHandlerProps> = ({ statusCode = null }) => {
  if (statusCode === 404) return <Navigate to="/404" />;

  return null;
};

export default ErrorHandler;
