/* node_modules */
import React, { useState } from 'react';
import classNames from 'classnames/bind';

/* style imports */
import styles from './Tooltip.scss';
import InfoBox from '../InfoBox';

const cx = classNames.bind(styles);

interface TooltipProps {
  children: React.ReactNode;
  text: string|{
    id: string,
    defaultMessage: string
  };
  iconClass?: string;
  align?: 'left'|'right';
  responsive?: boolean;
  withIcon?: boolean;
}

const Tooltip:React.FC<TooltipProps> = ({
  children,
  text,
  iconClass = '',
  align = 'right',
  responsive = false,
  withIcon = true,
  ...props
}) => {
  const [showTip, setShowTip] = useState(false);

  const toggleTip = () => {
    setShowTip(!showTip);
  };

  if (!withIcon) {
    return (
      <span {...props} className={cx('Tooltip', { 'Tooltip--ShowTip': showTip })}>
        <InfoBox text={text} className={cx('Tooltip__Content', { 'Tooltip__Content--Left': align === 'left', 'Tooltip__Content--Responsive': responsive })} />
        <div
          onMouseEnter={toggleTip}
          onMouseLeave={toggleTip}
        >
          {children}
        </div>
      </span>
    );
  }

  return (
    <span {...props} className={cx('Tooltip', { 'Tooltip--ShowTip': showTip })}>
      <span
        role="img"
        className={cx('Tooltip__Handle', iconClass || 'ion-information-circled')}
        onMouseEnter={toggleTip}
        onMouseLeave={toggleTip}
      />
      <InfoBox text={text} className={cx('Tooltip__Content', { 'Tooltip__Content--Left': align === 'left', 'Tooltip__Content--Responsive': responsive })} />
      {children}
    </span>
  );
};

interface SimpleTooltipProps {
  children: React.ReactNode;
  text: string;
}

export const SimpleTooltip:React.FC<SimpleTooltipProps> = ({ children, text }) => (
  <div className={cx('SimpleTooltip')}>
    { children }
    <div className={cx('SimpleTooltip_Bottom')}>
      <span>{text}</span>
      <i />
    </div>
  </div>
);

export default Tooltip;
