import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';

import styles from './Button.scss';

const cx = classNames.bind(styles);

interface ButtonProps {
  disabled?: boolean;
  className?: string | null;
  to?: string | null;
  children: React.ReactNode;
  color?: 'blue' | 'flatBlue' | 'black' | 'red' | 'transparent';
  size?: 'small' | 'tiny' | null;
  style?: React.CSSProperties;
  onClick?: ((e: React.MouseEvent<HTMLButtonElement>) => void),
}


/**
 * Default button component. Built upon for other types.
 */
const Button = ({
  className = null,
  to = null,
  children,
  color = 'blue',
  size = null,
  disabled = false,
  ...props
}: ButtonProps) => {
  const newClassName = cx(className, 'Button', {
    [`Button--${color}`]: color,
    [`Button--${size}`]: size,
    'Button--disabled': disabled,
  });

  if (to && to?.startsWith('http')) {
    return <a {...props} href={to} className={newClassName}>{children}</a>;
  }
  if (to) {
    return <Link {...props} to={to} className={newClassName}>{children}</Link>;
  }

  return (
    <button type="button" {...props} className={newClassName}>
      {children}
    </button>
  );
};

/**
 * HOC that adds Button styles to an element
 * @param {React.ElementType} Component
 */
export const withButtonStyle = Component => (props) => {
  const color = props.color || null;
  const size = props.size || null;

  const className = [
    'Button',
    `Button--${color}`,
    `Button--${size}`,
  ];

  if (props.className) {
    className.push(props.className);
  }

  return (
    <Component {...props} className={cx(className)} />
  );
};

export default Button;
