const OME_BASE_URL = process.env.SCREENSHOTS_OME;
const THUMBNAILS_BASE_URL = process.env.THUMBNAILS_URL;

export function omeScreenshotUrl(streamId: number) {
  return `/screenshots/stream_${streamId}.webp`;
}


/**
 * Generate a small thumbnail for a stream
 */
export function thumbnail(streamId: number, extension: 'avif' | 'webp' | 'jpg' = 'avif') {
  const preset = 360;

  //We need to resize the raw screenshot
  return `${THUMBNAILS_BASE_URL}/_/${preset}/plain${omeScreenshotUrl(streamId)}@${extension}`;
  //return omeScreenshotUrl(streamId);
}

/**
 * Get a larger screenshot for a stream
 */
export function screenshot(streamId: number, extension: 'avif' | 'webp' | 'jpg' = 'avif') {
  const preset = 1080;

  //We want to use the webp straight from our server, not go through imgproxy for screenshots, at least for now
  return omeScreenshotUrl(streamId);
  //return `${THUMBNAILS_BASE_URL}/_/${preset}/plain${omeScreenshotUrl(streamId)}@${extension}`;
}