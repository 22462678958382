import React, { useEffect, useRef, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import Helmet from 'react-helmet';
import classNames from 'classnames/bind';
import propTypes from 'prop-types';
import useResizeObserver from '@react-hook/resize-observer';

import config from '~/config';

import StreamDescriptionContainer from '~/components/Stream/Description';
import StreamContainer, { visibleStreams } from '~/components/StreamContainer';
import { withRouter } from '~/hooks';

import {
  getPlayingStreams, setPlayingStreams, toggleBar,
  removeStream,
  isLargeMulti,
  STREAMSHOTS_BITRATE_THRESHOLD,
  is404,
  setSection,
} from '~/modules/streams';
import { isLoading } from '~/modules/loading';
import { STREAMS_PAGE } from '~/sections';
import Chat from '~/components/Chat';
import { addFlash, resetFlashes } from '~/modules/flashes';
import { preferences } from '~/services/storage';
import { setPerformantMultis, setPreference } from '~/modules/preferences';
import ConnectedFlashDrawer from '~/components/FlashDrawer';
import NotFound from '~/views/404';
import { screenshot } from '~/services/thumbnail';

import styles from './Watch.scss';

const cx = classNames.bind(styles);

/**
 * @type {React.FC<{ isBanned: boolean }>}
 */
const Error404 = ({ isBanned }) => {
  const showError = useSelector(is404);

  if (showError) {
    return <NotFound isBanned={isBanned} />;
  }

  return null;
};

Error404.propTypes = {
  isBanned: propTypes.bool.isRequired,
};


const useSize = (target) => {
  const [size, setSize] = React.useState({ height: 0, width: 0 });

  useEffect(() => {
    if (target.current) {
      const { height, width } = target.current.getBoundingClientRect();
      setSize({ height, width });
    }
  }, [target]);

  useResizeObserver(target, entry => setSize({ height: entry.contentRect.height, width: entry.contentRect.width }));
  return size;
};

interface User {
  username: string;
}

interface WrapperStreamProps {
  children: React.ReactElement[];
  streams: Stream[];
  viewLayout: string ;
}

const WrapperStream:React.FC<WrapperStreamProps> = ({ children, streams, viewLayout }) => {
  const ref = useRef<HTMLElement | null>(null);
  const focused = useSelector(state => state.watchUI.focused);
  const chatRooms = useSelector(state => state.entities.chatRooms);
  const flashes = useSelector(state => state.flashes);
  const [marginBottom, setMarginBottom] = useState(0);
  const [countStreams, setCountStreams] = useState(0);
  const size = useSize(ref);

  useEffect(() => {
    setCountStreams(visibleStreams(streams, window.location, focused, chatRooms).length);
  }, [focused, chatRooms, streams]);

  useEffect(() => {
    setTimeout(() => {
      if (viewLayout === 'Theater' && ref.current && countStreams && !flashes.length) {
        const lastStream = (focused === null
          ? ref.current.lastChild?.lastChild
          : ref.current.lastChild?.firstChild) as HTMLElement;
        const marginB = -(size.height - lastStream.offsetHeight - parseFloat(lastStream.style.top));
        if (Math.abs(marginB) > 1) {
          setMarginBottom(marginB);
        } else {
          setMarginBottom(0);
        }
      } else {
        setMarginBottom(0);
      }
    }, 250);
  }, [viewLayout, ref, countStreams, size, flashes, focused]);

  return (
    <>
      {viewLayout === 'Theater' && (
        <style>
          {`
            :root {
              --margin-bottom-watch: ${marginBottom}px;
            }
          `}
        </style>
      )}
      <section ref={ref} className={cx('Stream', { mobile: viewLayout === 'Mobile', desktop: viewLayout === 'Desktop', theater: viewLayout === 'Theater' })}>
        {children}
      </section>
    </>
  );
}

interface StreamExtended extends Stream {
  bitrate: number;
  role:'user'|'admin'|'banned'|'curator';
  title: string;
  preview: {
    url: string;
  };
}

interface WatchProps {
  addFlash: Function;
  toggleBar: Function;
  clearStreams: Function;
  removeStream: Function;
  viewLayout: string;
  streams: StreamExtended[];
  user?: User;
  chatHidden?: boolean;
  descHidden: boolean;
  isFetching: boolean;
  isLargeMulti: boolean;
  performantMultis: boolean;
  warnPerformantMultis: boolean;
  setPerformantMultis: Function;
  setWarnPerformantMultis: Function;
  offlineStreamsHidden: boolean;
}

interface WatchState {
  viewportHeight: number | null;
  renderDate: number;
  chatEnabled: boolean;
  showStreamSection: boolean;
}

export class Watch extends React.Component<WatchProps, WatchState> {
  onHide: any;
  constructor(props) {
    super(props);

    this.state = {
      // For the chat on mobile
      viewportHeight: null,
      renderDate: Date.now(),
      chatEnabled: true,
      showStreamSection: false,
    };

    this.onHide = this.props.toggleBar.bind(this);
  }


  componentDidMount() {
    const { isLargeMulti, warnPerformantMultis, performantMultis, offlineStreamsHidden, streams } = this.props;

    this.setState({ viewportHeight: Math.max(window.innerHeight, 550) });

    if (isLargeMulti && (performantMultis && warnPerformantMultis)) {
      this.showPerformantMultisWarning();
    }

    if (offlineStreamsHidden) {
      this.showOfflineStreamsHiddenWarning();
    }

    streams.forEach((stream) => {
      if (stream.bitrate && stream.bitrate > STREAMSHOTS_BITRATE_THRESHOLD) {
        this.showHighBitrateWarning(stream.username);
      }
    });
    this.setState({ showStreamSection: true });
  }

  componentWillUnmount() {
    const { clearStreams } = this.props;

    clearStreams();
  }

  onChatError() {
    this.props.addFlash('error', 'There was an error with the chat');
    this.setState({ chatEnabled: false });

    window.setTimeout(() => this.setState({ chatEnabled: true }), 5000);
  }

  showPerformantMultisWarning() {
    const { addFlash, setWarnPerformantMultis, setPerformantMultis } = this.props;

    const message = {
      id: 'PerformantMultis_Warning',
      defaultMessage: 'This is a large multistream - for improved performance, they have all loaded the streamshots player. Click the gear in the bottom right of a stream to load the normal player (HLS).',
    };

    addFlash('info', message, false, [
      {
        label: {
          id: 'PerformantMultis_DoNotRemind',
          defaultMessage: "Don't remind me again",
        },
        onClick: (close) => {
          preferences.setItem('warnPerformantMultis', 0);
          setWarnPerformantMultis(false);
          close();
        },
      },
      {
        label: {
          id: 'PerformantMultis_Disable',
          defaultMessage: 'Disable performant multis',
        },
        onClick: (close) => {
          preferences.setItem('performantMultis', 0);
          setPerformantMultis(false);
          close();
        },
      },
    ]);
  }

  showOfflineStreamsHiddenWarning() {
    const { addFlash } = this.props;

    const message = {
      id: 'OfflineHidden_Warning',
      defaultMessage: 'This is a multistream with offline streams, which are hidden. They\'ll reappear when they go live',
    };

    const hideTimeout = 1 * 60 * 1000;

    addFlash('info', message, hideTimeout);
  }

  showHighBitrateWarning(username) {
    const { addFlash } = this.props;

    const message = {
      id: 'HighBitrate_Warning',
      defaultMessage: '{username} is using inefficient stream settings (bitrate over 4500kbps), only the streamshots player is available for their stream',
    };

    addFlash('info', {
      ...message,
      values: {
        username,
      },
    }, false);
  }

  render() {
    const {
      chatHidden = false,
      descHidden,
      streams,
      isFetching,
      user = { username: null },
      removeStream,
      viewLayout,
    } = this.props;

    const {
      viewportHeight,
      renderDate,
      chatEnabled,
      showStreamSection,
    } = this.state;

    const className = cx('WatchContainer', {
      hideChat: chatHidden,
      hideDesc: descHidden,
      partial: (!chatHidden && descHidden) || (!descHidden && chatHidden),
      show: !chatHidden && !descHidden,
      mobile: viewLayout === 'Mobile',
      desktop: viewLayout === 'Desktop',
      theater: viewLayout === 'Theater',
    });

    const isBanned = streams.some(stream => stream.role === 'banned');
  
    if (isFetching || !streams.length || isBanned) {
      // Should show error if it's not fetching and there are no streams
      return (
        <div className="WatchContainer loading">
          <Helmet title="Loading..." />
          <Error404 isBanned={isBanned} />
        </div>
      );
    }
    const metaTitle = streams[0].user?.username;

    const metaImageUrl = streams[0].preview.url || screenshot(streams[0].id as number, 'jpg');

    const metaDescription = streams[0].title;

    return (
      <div>
        <style>
          {`
            @media(max-width: 700px) {
              .${styles.WatchContainer} {
                --viewport-height: ${viewportHeight}px
              }
            }
          `}
        </style>
        <Helmet>
          <meta name="twitter:title" content={`${metaTitle} - Piczel.tv`} />
          <meta name="twitter:image" content={metaImageUrl} />
          <meta name="twitter:description" content={metaDescription} />
          <meta property="og:title" content={`${metaTitle} - Piczel.tv`} />
          <meta property="og:image" content={metaImageUrl} />
          <meta property="og:description" content={metaDescription} />
          <meta
            property="og:url"
            content={`https://piczel.tv/watch/${streams[0].user.username}`}
          />
          <title>{metaTitle}</title>
        </Helmet>
        <div className={className}>
          <StreamDescriptionContainer
            hide={descHidden ? 'right' : 'left'}
            onHide={() => this.onHide('desc')}
            user={user}
            streams={streams}
          />
          {
            showStreamSection && (
              <WrapperStream streams={streams} viewLayout={viewLayout}>
                <div className={styles.FlashDrawer}>
                  <ConnectedFlashDrawer />
                </div>
                <StreamContainer streams={streams} removeStream={removeStream} />
              </WrapperStream>
            )
          }

          { chatEnabled && <Chat onError={() => this.onChatError()} streams={streams} user={user} /> }
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const closedStreams = state.streams.sections.closed;
  const streams = getPlayingStreams(state).filter(stream => !closedStreams.includes(stream.username));

  const hasOfflineStreams = streams.some(stream => !stream.live); 
  const hasLiveStreams = streams.some(stream => stream.live);
  const { viewLayout } = state.chat.options;

  const props = {
    viewLayout,
    isSignedIn: state.currentUser.isSignedIn,
    streams,
    isFetching: isLoading(state.loading, STREAMS_PAGE),
    user: state.currentUser.data,
    descHidden: state.watchUI.descHidden,
    chatHidden: state.watchUI.chatHidden,
    isLargeMulti: isLargeMulti(state),
    performantMultis: state.preferences.performantMultis,
    warnPerformantMultis: state.preferences.warnPerformantMultis,
    offlineStreamsHidden: hasLiveStreams && hasOfflineStreams,
  };

  return props;
}

function mapDispatchToProps(dispatch) {
  return {
    /**
     * remove a player
     * @param {string} username
     */
    removeStream(username) {
      return dispatch(removeStream(username));
    },
    
    clearStreams() {
      dispatch(setPlayingStreams([]));
      dispatch(setSection('closed', []));
    },
    
    toggleBar(item) {
      dispatch(toggleBar(item));
    },

    addFlash(kind, msg, autoclose, actions) {
      dispatch(addFlash(kind, msg, autoclose, actions));
    },

    clearFlashes() {
      dispatch(resetFlashes());
    },

    setPerformantMultis(value) {
      dispatch(setPerformantMultis(value));
    },

    setWarnPerformantMultis(value) {
      dispatch(setPreference('warnPerformantMultis', value));
    },
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Watch));
