/* node_modules */
import React from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { IntlProvider } from 'react-intl';
import propTypes from 'prop-types';
import ReactGA from 'react-ga4';
import * as Sentry from '@sentry/react';
import { Outlet } from 'react-router-dom';

/* local imports */
// import routes from '~/routes';

import { fetchCurrentUser, FETCHING_CURRENTUSER } from '~/modules/currentUser';
import { isLoading } from '~/modules/loading';

import { isQueryParamSet, getParameterByName } from './helpers/query';
import { preferences } from '~/services/storage';
import Layout from '~/views/Layout';
import Loading from '~/components/Loading';
import { addFlash, resetFlashes } from './modules/flashes';
import { setPreference } from './modules/preferences';
import ServiceWorker from './helpers/serviceWorker';
import { withRouter } from './hooks';

/* style imports */
import './scss/base.scss';
import { Dispatch } from 'redux';
import { Router } from '@sentry/react/types/types';

interface AppProps {
  currentUser: {
    isSignedIn: boolean;
    data: {
      username: string;
      stream: {
        parent_streamer: string;
        offline_peers: number;
        in_multi: boolean;
        live: boolean;
        has_b_frames: boolean;
      }
    }
  }
  isLoading: boolean;
  translations: TranslationsState;
  dispatch: Dispatch;
  warnOfflineMultiMember: boolean;
  warnWebRTCBFrames: boolean;
  warnOfflineMultiHost: boolean;
  router: Router;
  navigate: {
    listen: Function;
  }
}

class App extends React.Component<AppProps> {
  removeHistoryListener: any;
  constructor(props) {
    super(props);
    this.state = { prevPathname: '' };
  }

  componentDidMount() {
    const {
      currentUser: { isSignedIn, data: currentUser }, dispatch, warnOfflineMultiMember, warnOfflineMultiHost, warnWebRTCBFrames, router,
    } = this.props;

    if (preferences && isQueryParamSet('referred_by')) {
      preferences.setItem('referred_by', getParameterByName('referred_by'));
      dispatch(setPreference('referred_by', getParameterByName('referred_by')));
    }

    if (isSignedIn && currentUser) {
      Sentry.setUser({
        username: currentUser.username,
      });
    }

    if (warnWebRTCBFrames && isSignedIn && currentUser?.stream?.live && currentUser?.stream?.has_b_frames) {
      const message = {
        id: 'warnWebRTCBFrames',
        defaultMessage: 'Your current settings are incompatible with our WebRTC player, only HLS is available.',
      };

      dispatch(addFlash('info', message, false, [
        {
          to: '/optimizations',
          label: {
            id: 'warnWebRTCBFrames_Optimizations',
            defaultMessage: 'Fix it',
          },
        },
        {
          label: {
            id: 'warnWebRTCBFrames_Disable',
            defaultMessage: 'Don\'t warn me again',
          },
          onClick(close) {
            dispatch(setPreference('warnWebRTCBFrames', false));
            preferences.setItem('warnWebRTCBFrames', 0);
            close();
          },
        },
      ]));
    }

    let offlineWarningShown = false;
    this.removeHistoryListener = router.subscribe((state) => {
      const action = state.historyAction;
      ReactGA.send({
        hitType: 'pageview',
        page: window.location.pathname,
        title: document.title,
      });

      if (action === 'REPLACE' && this.state.prevPathname !== '' && this.state.prevPathname !== '/login' && this.state.prevPathname !== window.location.pathname) {
        dispatch(resetFlashes());
      }
      this.setState({ prevPathname: window.location.pathname });

      if (isSignedIn && !offlineWarningShown) {
        const isMultiMember = currentUser.stream.in_multi && currentUser.stream.parent_streamer;
        const isHostingMulti = currentUser.stream.in_multi && !currentUser.stream.parent_streamer;
        const manyOfflinePeers = currentUser.stream.offline_peers > 2;
        const isOffline = !currentUser.stream.live;

        offlineWarningShown = (warnOfflineMultiHost && isHostingMulti && manyOfflinePeers) || (warnOfflineMultiMember && isMultiMember && isOffline);

        if (warnOfflineMultiHost && isHostingMulti && manyOfflinePeers) {
          const message = {
            id: 'MultiWarn_HostingOffline',
            defaultMessage: 'You have many offline streams in your multi - consider removing them and invite them back when they are next live.',
          };

          dispatch(addFlash('info', message, false, [
            {
              to: '/account/stream/multistream',
              label: {
                id: 'MultiWarn_SettingsLink',
                defaultMessage: 'Go to your multistream settings',
              },
            },
            {
              label: {
                id: 'MultiWarn_Disable',
                defaultMessage: 'Don\'t warn me again',
              },
              onClick(close) {
                dispatch(setPreference('warnOfflineMultiHost', false));
                preferences.setItem('warnOfflineMultiHost', 0);
                close();
              },
            },
          ]));
        }

        if (warnOfflineMultiMember && isMultiMember && isOffline) {
          const message = {
            id: 'MultiWarn_Offline',
            defaultMessage: 'Your stream is currently joined in a multistream but are offline - you may want to leave the multi and rejoin when you plan to stream with them again.',
          };

          dispatch(addFlash('info', message, false, [
            {
              to: '/account/stream/multistream',
              label: {
                id: 'MultiWarn_SettingsLink',
                defaultMessage: 'Go to your multistream settings',
              },
            },
            {
              label: {
                id: 'MultiWarn_Disable',
                defaultMessage: 'Don\'t warn me again',
              },
              onClick(close) {
                dispatch(setPreference('warnOfflineMultiMember', false));
                preferences.setItem('warnOfflineMultiMember', 0);
                close();
              },
            },
          ]));
        }
      }
    });
    // register sw
    if (ServiceWorker.isSupported()) {
      ServiceWorker.register();
    }
  }

  componentDidUpdate(prevProps) {
    const { dispatch, currentUser: { isSignedIn } } = this.props;

    if (isSignedIn && prevProps.currentUser.isSignedIn !== isSignedIn) {
      dispatch(fetchCurrentUser(false));
    }
  }

  componentWillUnmount() {
    if (this.removeHistoryListener) {
      this.removeHistoryListener();
    }
  }

  render() {
    const {
      isLoading,
      translations: {
        currentLocale,
        messages,
      },
    } = this.props;

    return (
      <IntlProvider locale={currentLocale} messages={messages[currentLocale]} key={currentLocale}>
        <Helmet>
          <meta
            property="og:image"
            content="https://piczel.tv/img/piczel_tv_full.png"
          />
          <meta property="og:image:width" content="1708" />
          <meta property="og:image:height" content="1311" />
        </Helmet>
        {isLoading ? <Loading />
          : (
            <Layout>
              <Outlet />
            </Layout>
          )
        }
      </IntlProvider>
    );
  }
};

/**
 * @param {RootState} state
 */
const mapStateToProps = state => ({
  currentUser: state.currentUser,
  translations: state.translations,
  statusCode: state.status,
  isLoading: isLoading(state.loading, 'app.translations') || isLoading(state.loading, FETCHING_CURRENTUSER),
  warnOfflineMultiMember: state.preferences.warnOfflineMultiMember,
  warnWebRTCBFrames: state.preferences.warnWebRTCBFrames,
  warnOfflineMultiHost: state.preferences.warnOfflineMultiHost,
});

export default withRouter(connect(mapStateToProps)(App));
