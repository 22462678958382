import React from 'react';
import ReactModal from 'react-modal';

interface ModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  children: React.ReactNode;
}

const Modal:React.FC<ModalProps> = ({ children, ...props }) => (
  <ReactModal
    style={
      {
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.75)',
          zIndex: 1100,
        },
        content: {
          position: 'absolute',
          top: '50%',
          left: '50%',
          bottom: null,
          right: null,
          transform: 'translate(-50%, -50%)',
          border: '1px solid #666',
          background: '#333',
          overflow: 'auto',
          borderRadius: '4px',
          outline: 'none',
          padding: '20px',
          zIndex: 1100,
          textAlign: 'center',
          maxHeight: '95vh',
          minWidth: '500px'
        },
      }
    }
    {...props}
  >
    {children}
  </ReactModal>
);

export default Modal;
