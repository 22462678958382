/// <reference path="../../types/index.d.ts" />

import React from 'react';
import { useAddStream } from '~/hooks';

interface WrapperProps {
  stream: Stream;
  children: React.ReactNode[]|React.ReactNode;
}

const Wrapper: React.FC<WrapperProps> = ({ stream, children }) => {
  const addStream = useAddStream();

  return (
    <button style={{ display: 'contents' }} type="button" onClick={e => addStream(stream, e)}>
      {children}
    </button>
  )
};

export default Wrapper;

/**
 * HOC to add onClick listener that adds a stream to the currently playing streams
 * @param {typeof React.Component} Component
 */
export const withAddStream = Component => (props) => {
  const addStream = useAddStream();

  return <Component {...props} onClick={e => addStream(props.stream, e)} />;
};
