import React, { useContext, useMemo, useCallback, useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { ChatContext, events } from './ChatContext';
import { getBlockList } from '~/modules/chat';
import Button from '~/components/Button';

interface BlockButtonProps {
  user: {
    username: string;
    userId: string;
  };
}

const BlockButton: React.FC<BlockButtonProps> = ({ user }) => {
  const { client: socket } = useContext(ChatContext);
  const blockList = useSelector(getBlockList);
  const isBlocked = useMemo(() => blockList.findIndex(blocked => blocked.uid === user.userId) !== -1, [blockList, user]);
  const [loading, setLoading] = useState(false);
  
  const setBlocked = useCallback((value) => {
    if (!socket) return;
    setLoading(true);

    socket.emit(events.SET_BLOCKED, {
      userId: user.userId,
      value,
    });
  }, [socket, user]);

  useEffect(() => {
    setLoading(false);
  }, [blockList]);

  return (
    <Button disabled={loading} color="black" size="small" onClick={() => setBlocked(!isBlocked)}>
      {
        isBlocked ? (
          <FormattedMessage id="OverviewButtons_Unblock" defaultMessage="Unblock {username}" values={{ username: user.username }} />
        ) : (
          <FormattedMessage id="OverviewButtons_Block" defaultMessage="Block {username}" values={{ username: user.username }} />
        )
      }
    </Button>
  );
};

export default BlockButton;
