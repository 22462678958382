import React, {
  useRef, useEffect, useState, useMemo, useCallback,
} from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';;
import classNames from 'classnames/bind';
import { FormattedMessage, useIntl } from 'react-intl';

import { isMessageWithMention } from '~/modules/chat';
import Avatar, { DefaultAvatar } from '~/components/Avatar';
import GifFreeze from '~/helpers/GifFreeze';

import StatusIcons from './StatusIcons';
import RaffleWinner from './RaffleWinner';

import LinkPreview from './LinkPreview';

import styles from './Message.scss';
import './Message.global.css';

const cx = classNames.bind(styles);

const showSpoiler = (e) => {
  const spoiler = e.currentTarget;
  spoiler.setAttribute('aria-expanded', true);
};

const messageComponents = {
  RaffleWinner,
};

interface StreamLinkProps {
  user: User;
}

const StreamLink: React.FC<StreamLinkProps> = ({ user }) => (
  <Link to={`/watch/${user}`}>
    <FormattedMessage id="Chat_LeftMulti_Link" defaultMessage="Click to go to their stream" />
  </Link>
);

interface Message {
  serverMessage: boolean;
  text: string;
  user: User;
}

interface MessageProps {
  message: Message;
  setOverview: () => void;
  animateGifs?: boolean;
  displayColors?: boolean;
  remove?: any;
  display?: 'normal' | 'compact';
  currentUser: User;
  disableTimestamps?: boolean;
  onLoad: any;
  onLoadPreview: any;
}

const Message: React.FC<MessageProps> = ({
  message, setOverview, animateGifs = true, displayColors = true, remove = null,
  display = 'normal', currentUser, disableTimestamps = false, onLoad, onLoadPreview,
}) => {
  const now = dayjs();

  const intl = useIntl();
  const ref = useRef(null);
  const messages = Array.isArray(message) ? message : [message];
  const mainMessage = messages[0];
  const [hovering, setHovering] = useState(false);

  const createdAt = useMemo(() => (mainMessage.created_at ? dayjs(mainMessage.created_at) : null), [mainMessage]);
  const isOld = useMemo(() => createdAt && (now.diff(createdAt, 'hours') > 6), [createdAt]);

  const replyMessage = useCallback((e, to) => {
    if (to !== currentUser.username) {
      const event = new CustomEvent('reply-message', {
        bubbles: true,
        detail: { to },
      });
      e.target.dispatchEvent(event);
    }
  }, [currentUser]);

  const timestamp = useMemo(() => {
    if (!createdAt) return null;

    const diff = now.diff(createdAt, 'days');

    if (diff > 1) {
      if (display === 'compact') {
        return (
          intl.formatDate(createdAt.toDate(), {
            year: 'numeric',
            month: 'numeric',
            day: '2-digit',
          })
        );
      }

      return (
        `${intl.formatDate(createdAt.toDate(), {
          year: 'numeric',
          month: 'long',
          day: '2-digit',
        })} ${
          intl.formatMessage({ id: 'Message_Oldtimestamp', defaultMessage: 'at {time}' }, {
            time: createdAt.format('HH:mm'),
          })
        }`
      );
    }

    if (now.isSame(createdAt, 'day')) {
      if (display === 'compact') {
        return (
          createdAt.format('HH:mm')
        );
      }
      return (
        intl.formatMessage({
          id: 'Message_TodayTimestamp',
          defaultMessage: 'Today at {time}',
        }, {
          time: createdAt.format('HH:mm'),
        })
      );
    }

    if (display === 'compact') {
      return (
        `${intl.formatDate(createdAt?.toDate(), {
          year: 'numeric',
          month: 'numeric',
          day: '2-digit',
        })} ${
          createdAt?.format('HH:mm')
        }`
      );
    }


    return (
      intl.formatMessage({
        id: 'Message_YesterdayTimestamp',
        defaultMessage: 'Yesterday at {time}',
      }, {
        time: createdAt.format('HH:mm'),
      })
    );
  }, [mainMessage.created_at, display]);

  useEffect(() => {
    /**
     * @type {HTMLDivElement|null}
     */
    const content = ref.current;
    const onError = (elm) => {
      const span = document.createElement('span');
      span.innerText = elm.alt;
      elm.parentNode.replaceChild(span, elm);
    };

    if (!content) return;
    let gf;
    const emotes = content.querySelectorAll(`.${styles.Message__Content} img`);
    const spoilers = content.querySelectorAll(`.${styles.Message__Content} .spoiler`);

    if (!animateGifs) {
      gf = new GifFreeze(emotes);
      gf.freeze(styles.FrozenGif);
    }

    if (spoilers.length) {
      spoilers.forEach((spoiler) => {
        spoiler.addEventListener('click', showSpoiler);
      });
    }

    if (onLoad) emotes.forEach(e => e.addEventListener('load', onLoad));
    emotes.forEach((elm) => {
      fetch(elm.src).then((res) => {
        if (res.status === 404) {
          onError(elm);
        }
      }).catch(() => {
        onError(elm);
      });
    });

    // eslint-disable-next-line consistent-return
    return () => {
      if (!animateGifs) gf.unfreeze();
      if (onLoad) emotes.forEach(e => e.removeEventListener('load', onLoad));
      if (spoilers.length) spoilers.forEach(spoiler => spoiler.removeEventListener('click', showSpoiler));
    };
  }, [animateGifs, message]);

  useEffect(() => {
    /**
     * @type {HTMLDivElement|null}
     */
    const content = ref.current;
    if (!content) return;

    // wrap text portions in <span> tags so CSS for emojis works well
    content.querySelectorAll(`.${styles.Message__Content} > div`).forEach((msg) => {
      msg.childNodes.forEach((node) => {
        if (node.nodeType === Node.TEXT_NODE) {
          const span = document.createElement('span');
          span.appendChild(node.cloneNode());
          msg.replaceChild(span, node);
        }
      });
    });
  }, [message]);


  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      style={{ height: 'auto' }}
      onClick={e => mainMessage.isPrivate && replyMessage(e, mainMessage.user.username)}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      className={cx('Message', {
        'Message--Hovering': hovering,
        'Message--ServerMessage': mainMessage.serverMessage,
        'Message--PrivateMessage': mainMessage.isPrivate,
        'Message--ReplyableMessage': mainMessage.isPrivate && mainMessage.user.username !== currentUser.username,
        'Message--NoTimestamp': (mainMessage.alert || !timestamp),
        'Message--Compact': display === 'compact',
        'Message--Old': isOld,
      })}
    >
      {
        !mainMessage.alert && timestamp && !disableTimestamps && (
          <div title={timestamp} className={styles.Message__Timestamp}>
            <div className={styles.Message__TimestampContent}>
              {
                mainMessage.isPrivate && (
                  <span className={styles.Message__Username}>{`${mainMessage.user.username !== mainMessage.roomName ? `To ${mainMessage.roomName}` : mainMessage.user.username} | `}</span>
                )
              }
              {timestamp}
            </div>
          </div>
        )
      }
      {
        (!mainMessage.serverMessage && mainMessage.user && !mainMessage.isPrivate) && (
        <>
          <button type="button" onClick={e => setOverview(mainMessage.user, e)} className={styles.Message__Avatar}>
            {
              mainMessage.user.piczel_user_id ? (
                <Avatar 
                  controlled 
                  hovering={hovering}
                  username={mainMessage.user.username}
                  userId={mainMessage.user.piczel_user_id}
                />
              ) : (
                <DefaultAvatar />
              )
            }
          </button>
          <button
            style={displayColors ? { '--color': mainMessage.user?.color || '#3db9ea' } : undefined}
            type="button"
            onClick={e => setOverview(mainMessage.user, e)}
            className={styles.Message__User}
          >
            {mainMessage.user?.username || 'Sender'}

            <StatusIcons user={mainMessage.user} />
          </button>
        </>
        )
      }
      {/* eslint-disable-next-line react/no-danger */}
      <div ref={ref} className={styles.Message__ContentWrapper}>
        {
          messages.map((msg) => {
            const mentionsMe = isMessageWithMention(currentUser, msg);
            return (
              <div id={`Message_${msg.id}`} key={msg.id} className={cx('Message__Content', { [`Message__Content--Alert-${msg.alert}`]: msg.alert, 'Message__Content--MentionsMe': mentionsMe, 'Message__Content--Deleted': msg.deleted })}>
                { msg.translatable && <FormattedMessage id={msg.translatable.id} defaultMessage={msg.translatable.defaultMessage} values={msg.translatable.values} /> }
                { msg.component && msg.component.id && <FormattedMessage id={msg.component.id} defaultMessage={msg.component.defaultMessage} values={{...msg.component.values, streamLink:msg.component.id === 'Chat_LeftMulti'? <StreamLink user={msg.component.values.user} /> :null}} /> }
                { msg.component && msg.component.name && (React.createElement(messageComponents[msg.component.name], msg.component.props))}
                { msg.text && <LinkPreview onLoadPreview={onLoadPreview} msg={msg} /> }
                {
                  msg.deleted && (<FormattedMessage id="Message_Deleted" defaultMessage="This message was removed by {user}" values={{ user: msg.deleted_by }} />)
                }
                {
                  (!msg.deleted && remove && !msg.isPrivate) ? (
                    <button onClick={() => remove(msg.id)} type="button" title={intl.formatMessage({ id: 'Message_DeleteTooltip', defaultMessage: 'Delete' })} className={styles.Message__Delete}>
                      <span role="img" className="ion-close" />
                    </button>
                  ) : null
                }
              </div>
            );
          })
        }
      </div>
    </div>
  );
};

export default React.memo(Message);
