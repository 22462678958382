import React from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './index.scss';

interface InfoBoxProps {
  text: string|{
    id: string,
    defaultMessage: string
  };
  className?: string;
}

const InfoBox:React.FC<InfoBoxProps> = ({ text, className = '', ...props }) => {
  const message = typeof text === 'string' ? text : <FormattedMessage {...text} />;
  return (
    <span {...props} className={`${styles.InfoBox} ${className}`}>{message}</span>
  );
};

export default InfoBox;
