import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames/bind';

import { searchTags } from '~/modules/gallery';
import FormattedMessage from '~/components/common/FormattedMessage';

import styles from './TagField.scss';

const cx = classNames.bind(styles);

interface TagFieldProps {
  children: React.ReactNode;
  name: string;
  value: string[];
  onChange: (value: string[]) => void;
  fullWidth?: boolean;
}

const TagField:React.FC<TagFieldProps> = ({ children, name, value, onChange, fullWidth = false }) => {
  const dispatch = useDispatch();
  const [tags, setTags] = useState(value);
  const [suggestions, setSuggestions] = useState([]);
  const [selectedSuggest, selectSuggest] = useState(0);
  let timeout;

  const resetSuggestions = () => {
    setSuggestions([]);
    selectSuggest(0);
  };

  useEffect(() => {
    if (onChange) onChange(tags);

    resetSuggestions();
  }, [tags]);

  const handleChange = (e) => {
    // Search for suggested tags (autocomplete)
    if (e.currentTarget.value.length >= 3) {
      const searchString = e.currentTarget.value;
      clearTimeout(timeout);

      timeout = setTimeout(() => {
        dispatch(searchTags(searchString))
          .then(foundTags => setSuggestions(foundTags));
      }, 500);
    }

    // Divide into tags if necessary (contains ,)
    if (e.currentTarget.value.includes(',')) {
      const tempArr = e.currentTarget.value.split(',');

      e.currentTarget.value = tempArr.pop();
      setTags([
        ...tags,
        ...tempArr.filter((tag) => {
          const nTag = tag.trim();

          if (!nTag.length) return false;
          if (tags.includes(nTag)) return false;

          return true;
        }),
      ]);
    }
  };

  const handleKeyDown = (e) => {
    // On backspace, remove tags if value empty
    if (e.keyCode === 8 && !e.currentTarget.value.length) {
      const newArray = [...tags];
      newArray.splice(-1, 1);
      setTags(newArray);
    }

    // On enter, if suggestions use the selected one
    if (e.keyCode === 13 && suggestions[selectedSuggest]) {
      e.stopPropagation();
      e.preventDefault();

      if (tags.includes(suggestions[selectedSuggest])) return;

      setTags([
        ...tags,
        suggestions[selectedSuggest],
      ]);
      e.currentTarget.value = '';
    }

    if (e.keyCode === 38 && suggestions[selectedSuggest - 1]) selectSuggest(selectedSuggest - 1);
    if (e.keyCode === 40 && suggestions[selectedSuggest + 1]) selectSuggest(selectedSuggest + 1);
  };

  const handleClick = (i) => {
    const newArray = [...tags];
    newArray.splice(i, 1);
    setTags(newArray);
  };

  const addTag = (tag) => {
    const newArray = [...tags];
    newArray.push(tag);
    setTags(newArray);
  };

  return (
    <div className={cx('TagField', { 'TagField--fullWidth': fullWidth })}>
      {children && <label htmlFor={name}>{children}</label>}
      <div className={cx('TagField__Field')}>
        <div className={cx('TagField__Container')}>
          {tags.map((tag, i) => <span className={cx('TagField__Item')} onClick={() => handleClick(i)}>{tag}</span>)}
          <div className={cx('TagField__Input')}>
            <FormattedMessage id="TagField__Placeholder" defaultMessage="Cats, dogs, animals.">
              {msg => (
                <input
                  type="text"
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  placeholder={!tags.length && msg}
                />
              )}
            </FormattedMessage>
            {!!suggestions.length && (
              <ul className={cx('TagField__Suggestions')}>
                {suggestions.map((suggest, i) => (
                  <li
                    onClick={() => addTag(suggest)}
                    className={cx({ 'TagField__Suggestion--selected': selectedSuggest === i })}
                  >
                    {suggest}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
        <p className={cx('TagField__Hint')}>
          <FormattedMessage id="TagField__Hint" defaultMessage="Separate tags using commas, press backspace or click to delete." />
        </p>
      </div>
    </div>
  );
};

export default TagField;
