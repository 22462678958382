import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';

import { removeFlash } from '~/modules/flashes';
import FormattedMessage from '~/components/common/FormattedMessage';
import Button from '../Button';

import styles from './FlashDrawer.scss';
import { Dispatch } from 'redux';

const c = classNames.bind(styles);


export const Flash:React.FC<Flash & { close: any }> = ({
  kind,
  message,
  autoclose = true,
  close,
  actions,
}) => {
  const [isFading, setFading] = useState(false);
  const closeTimeout = typeof autoclose === 'number' ? autoclose : 2000;

  const fadeOut = () => {
    setFading(true);
    setTimeout(close, closeTimeout);
  };

  useEffect(() => {
    if (autoclose) setTimeout(fadeOut, Math.max(150, closeTimeout - 250));
  }, []);

  const classes = c([
    'FlashItem',
    `Flash__${kind}`,
  ], {
    fade: isFading,
  });

  return (
    <div className={classes}>
      <p>
        {
        typeof message === 'string'
          ? message
          : (
            <FormattedMessage {...message} />
          )
        }
      </p>
      {
        actions && (
          actions.map(action => (
            <Button onClick={action.onClick ? (() => action.onClick(close)) : undefined} to={action.to} color="transparent">
              {
                typeof action.label === 'string'
                  ? (action.label)
                  : (
                    <FormattedMessage {...action.label} />
                  )
              }
            </Button>
          ))
        )
      }
      <i className="ion-close" onClick={close} />
    </div>
  );
};

interface Actions {
  to: string;
  label: {
    id: string,
    defaultMessage: string
  };
  onClick: (close: () => void) => void;
}

interface Flash {
  kind: string;
  id: number;
  actions:Actions[];
  message: string|{
    id: string,
    defaultMessage: string
  };
  messageId?: string;
  autoclose?: boolean;
}

interface FlashDrawerProps {
  closeFlash: (id: number) => void;
  flashes: Flash[];
}

const FlashDrawer:React.FC<FlashDrawerProps> = ({
  closeFlash,
  flashes,
}) => (
  <div className={styles.FlashDrawer}>
    {flashes.map(flash => <Flash close={() => closeFlash(flash.id)} key={flash.id} {...flash} />)}
  </div>
);


interface ConnectedFlashDrawerProps {
  flashes:Flash[];
  dispatch: Dispatch;
}
class ConnectedFlashDrawer extends React.Component<ConnectedFlashDrawerProps> {
  constructor(props) {
    super(props);

    this.deleteFlash = this.deleteFlash.bind(this);
  }

  deleteFlash(id) {
    const { dispatch } = this.props;

    dispatch(removeFlash(id));
  }

  render() {
    return <FlashDrawer {...this.props} closeFlash={this.deleteFlash} />;
  }
}

const mapStateToProps = state => ({
  flashes: state.flashes,
});

export default connect(mapStateToProps)(ConnectedFlashDrawer);
