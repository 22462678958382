import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import classnames from 'classnames/bind';

import styles from './ErrorDisplay.scss';

const classNames = classnames.bind(styles);

/**
 * Used to show a message when videojs throws an error
 */
interface ErrorDisplayProps {
  stream: Stream;
  willRetry?: boolean;
  retryAfter?: number;
  vjsTech: string;
  setDefault: any;
  setPlayer: any;
  setVjsTech: any;
  nextRetry: number;
}

const ErrorDisplay:React.FC<ErrorDisplayProps> = ({
  stream, willRetry = false, retryAfter = 5, vjsTech, setDefault, setPlayer, setVjsTech, nextRetry,
}) => {
  const [dashArray, setDashArray] = useState(0);
  const [dashOffset, setDashOffset] = useState(0);

  useEffect(() => {
    if (willRetry) {
      const interval = setInterval(() => {
        const started = nextRetry - (retryAfter);
        const startedVal = Math.abs(Date.now() - started);

        const targetVal = retryAfter;

        const progress = startedVal / targetVal;

        const radius = 30;
        const arcLength = 2 * Math.PI * radius;

        const arcOffset = arcLength * (1 - progress);

        requestAnimationFrame(() => {
          setDashArray(arcLength);
          setDashOffset(arcOffset);
        });
      }, Math.round(1000 / 60));

      return () => {
        clearInterval(interval);
      };
    }
  }, [nextRetry, retryAfter, willRetry]);

  return (
    <div className={styles.ErrorDisplayIndicator}>
      <span role="img" className={willRetry ? 'ion-alert' : 'ion-android-close'} />

      <svg className={styles.ErrorDisplayIndicator__Timer} viewBox="0 0 66 66" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <circle
          className={classNames('ErrorDisplayIndicator__Timer--Bar', {
            'ErrorDisplayIndicator__Timer--BarNoRetries': !willRetry,
          })}
          r="30"
          cx="33"
          cy="33"
          fill="transparent"
          strokeDasharray={dashArray}
          strokeDashoffset={dashOffset}
        />
      </svg>

      <div className={styles.ErrorDisplayIndicator__Message}>
        { willRetry ? (
          <FormattedMessage
            id="ErrorDisplay_Message"
            defaultMessage="Something went wrong with the player, we're trying to fix it"
          />
        ) : (
          <FormattedMessage
            id="ErrorDisplay_NoRetries"
            defaultMessage="Something went wrong with the player, please refresh the page"
          />
        )}
      </div>
    </div>
  );
}

export default ErrorDisplay;
