// @ts-check
/**
 * Placeholder watch page to show while things are loading
 */
import React from 'react';

import { useSelector } from 'react-redux';
import classNames from 'classnames/bind';

import { getOptions } from '~/modules/chat';
import styles from './index.scss';

const cx = classNames.bind(styles);

interface WatchSkeletonProps {
  children: React.ReactNode;
  loading?: boolean;
}

const WatchSkeleton: React.FC<WatchSkeletonProps> = ({ children, loading = false }) => {
  const { viewLayout } = useSelector(getOptions);

  return (
    <div className={`${cx('WatchSkeleton', { mobile: viewLayout === 'Mobile', desktop: viewLayout === 'Desktop' })} ${(loading ? styles.loading : '')}`}>
      <div className={cx('Description', { mobile: viewLayout === 'Mobile', desktop: viewLayout === 'Desktop' })} />
      <div className={cx('Streams', { mobile: viewLayout === 'Mobile', desktop: viewLayout === 'Desktop' })}>
        {children}
      </div>
      <div className={cx('Chat', { mobile: viewLayout === 'Mobile', desktop: viewLayout === 'Desktop' })} />
    </div>
  );
}

export default WatchSkeleton;
