// @ts-check
// eslint-disable-next-line spaced-comment
/// <reference path="../types/streams.d.ts" />
/**
* Sets up videoJS title bar
*/
import React from 'react';
import propTypes from 'prop-types';
import { FollowButton } from '../Button';
import styles from './index.scss';

interface TitleBarProps {
  stream: Stream & { bitrate: number };
  toggleFocus: false|((username: string) => void);
  removeStream: false|((username: string) => void);
  stopRecording: false|((username: string) => void);
  showBitrates: boolean;
}

const TitleBar: React.FC<TitleBarProps> = ({ stream, toggleFocus = false, removeStream = false, stopRecording = false, showBitrates }) => {
  return (
    <div className={styles.TitleBar}>
      <div className={styles['TitleBar--Username']}>
        {stream.username}
        {' '}
        {showBitrates ? `(${Math.round((stream.bitrate || 0) / 1000)}Kbps)` : '' }
      </div>
      <FollowButton small username={stream.username} />

      <div className={styles.TitleBar__Buttons}>
        {toggleFocus && (
          <button
            type="button"
            className={`ion-arrow-resize ${styles.TitleBarButton}`}
            onClick={() => toggleFocus(stream.username)}
            title={`Focus ${stream.username}'s stream`}
          />
        )}
        { (removeStream || stopRecording) && (
          <button
            type="button"
            title={stopRecording ? 'Stop this recording' : `Close ${stream.username}'s stream`}
            onClick={() => {
              if (stopRecording) {
                stopRecording(stream.username);
              } else if (removeStream) {
                removeStream(stream.username);
              }
            }}
            className={`ion-close-round ${styles.TitleBarButton}`}
          />
        )}
      </div>
    </div>
  );
};

export default TitleBar;