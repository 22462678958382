import React from 'react';
import classNames from 'classnames/bind';

import styles from './index.scss';

const cx = classNames.bind(styles);

interface AddStreamButtonProps {
  adding: boolean;
  disabled: boolean;
  toggleAdding: () => void;
}

const AddStreamButton: React.FC<AddStreamButtonProps> = (
  { adding = false, toggleAdding, disabled = false },
) => (
  <i
    tabIndex={0}
    role="button"
    onKeyDown={e => (e.key === 'Enter' || e.key === ' ') && !disabled && toggleAdding()}
    onClick={disabled ? undefined : toggleAdding}
    className={`${cx(styles.AddStreamButton, { adding, disabled })} ion-android-add-circle`}
  />
);

export default AddStreamButton;
