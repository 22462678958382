const Config = {

  api: process.env.PICZEL_API || 'https://piczel.tv/api',

  patreon_client_id:
    'j6PVIi66wlQ9g5HqSvxV4OM2q0fJVDAe9PLGq5YZKGgK7eT20-RtlV_YN5wIrs1S',
  patreon_redirect_uri: 'https://piczel.tv/account/premium?success=1',
  recaptcha: '6LcloB4TAAAAAE0j21YOCz0jFjJIrGIpm3IsrJ9X',

  ss_client_id: 'cd_1ohlxG54YlIb7s5uLWYII43zkmo8EbJubA5QCKrA',
  ss_redirect_uri: 'https://piczel.tv/account/premium',

  api_timezone: {
    // UTC +1
    hours: -4,
    minutes: 0,
  },

  previews: process.env.PREVIEWS_HOST || 'https://thumbnails.piczel.tv', // stream screenshots and thumbnails
  static: process.env.STATIC_URL || 'https://piczel.tv/static', // No trailing backslash
  chat_host: process.env.CHAT_HOST || 'https://chat.piczel.tv',
  chat_auth: process.env.CHAT_AUTH || 'https://chat.piczel.tv',
  avatarsHost: process.env.AVATARS_HOST || 'https://piczel.tv',

  languages: {
    en: 'English',
    fr: 'Français',
    nl: 'Nederlands',
    ko: '한국어',
    ja: '日本語',
    es: 'Español',
  },
  servers: [
    'rtmp://germany-ingest.piczel.tv:1935/live',
    'rtmp://japan-ingest.piczel.tv:1935/live',
    'rtmp://brazil-ingest.piczel.tv:1935/live',
  ],
};

export default Config;
