import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './List.scss';

const cx = classNames.bind(styles);

interface ListProps {
  className?: string;
  children: React.ReactNode;
}

const List:React.FC<ListProps> = ({ className = null, children, ...props }) => (
  <div className={cx('List', className)} {...props}>
    { children }
  </div>
);

export default List;
