// @ts-check
/* node_modules */
import React from 'react';
import classnames from 'classnames/bind';
import { withRouter } from '~/hooks';
import { NavigateFunction } from 'react-router-dom';


/* local imports */
import config from '~/config';

/* style imports */
import styles from './StreamPreview.scss';
import { thumbnail } from '~/services/thumbnail';

const cx = classnames.bind(styles);

function getStreamThumbnailURL(stream: Stream, extension: 'avif' | 'jpg' | 'webp' = 'avif') {
  return thumbnail(stream.id!, extension);
}

interface StreamPreviewProps {
  stream: Stream & { blur?: boolean };
  className?: string;
  navigate: NavigateFunction;
};

interface StreamPreviewState {
  image: string;
}

export class StreamPreview extends React.Component<StreamPreviewProps, StreamPreviewState> {

  constructor(props) {
    super(props);

    // TODO: clean up state.image
    this.state = {
      image: getStreamThumbnailURL(this.props.stream),
    };
  }

  handleClick(username, event) {
    if (!this.props.navigate) {
      return;
    }

    event.preventDefault();

    if (event.button === 1) {
      // open in new tab
      window.open(`/watch/${username}`, '_blank');
    } else {
      this.props.navigate(`/watch/${username}`);
    }
  }

  render() {
    /**
     * @type {{ stream: Stream, className?: string }}
     */
    const { stream, className } = this.props;
    const {
      live, adult, viewers, blur, username
    } = stream;
    const isPrivate = stream.is_private;

    // Load background image from screenshot if live, from offline image if available.
    const backgroundImage = live || isPrivate
      ? 'transparent'
      : 'url(/img/offline_thumbnail.jpeg) no-repeat center/cover';
    const privateImage = '/img/private_thumbnail.png';
    const { image: liveImage } = this.state;

    return (
      <a
        onClick={this.handleClick.bind(this, username)}
        href={`/watch/${username}`}
        style={{ background: backgroundImage }}
        className={cx({
          [className]: className,
          StreamPreview: true,
          'StreamPreview--offline': !live,
          'StreamPreview--adult': adult,
        })}
      >
        <div className={styles.StreamPreview__Mask} />

        {isPrivate ? (
          <img alt="Private stream" src={privateImage} className={styles.StreamPreview__Image} />
        ) : (
          live && (
            <picture className={cx({
              StreamPreview__Image: true,
              'StreamPreview__Image--blur': blur,
              nsfw: adult,
            })}>
              <source type="image/avif" srcSet={getStreamThumbnailURL(stream, 'avif')} />
              <source type="image/webp" srcSet={getStreamThumbnailURL(stream, 'webp')} />

              <img
                alt={`${username}'s stream thumbnail`}
                src={getStreamThumbnailURL(stream, 'jpg')}
              />
            </picture>
          )
        )}

        <div className={styles.StreamPreview__Inner}>
          <h3>{username}</h3>
        </div>
        {live && (
          <div className={styles.StreamPreview__ViewerCount}>
            <span className="ion-eye" />
            {' '}
            {viewers}
          </div>
        )}
      </a>
    );
  }
}

/**
 * @type {StreamPreview}
 */
export default withRouter(StreamPreview);
