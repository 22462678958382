import React from 'react';

import classNames from 'classnames/bind';

import styles from './AvatarGroup.scss';

const cx = classNames.bind(styles);

interface Props {
  children: React.ReactElement[];
  display?: number;
  className?: string | null;
}

const AvatarGroup: React.FC<Props> = ({ children, display = 3, className }) => (
  <div className={cx('AvatarGroup', className)}>
    {
        Array.from(children).slice(0, display).map((element, i) => React.cloneElement(element, {
          style: { zIndex: children.length - i },
          className: cx('AvatarGroup__Item'),
        }))
      }
    {
        children.length > display && (
          <div className={cx('AvatarGroup__More')}>
            +
            {children.length - display}
          </div>
        )
      }
  </div>
);


export default AvatarGroup;
