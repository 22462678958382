import React from 'react';

import classNames from 'classnames/bind';

import styles from './ListItem.scss';

const cx = classNames.bind(styles);

interface ListItemProps {
  className?: string;
  title: React.ReactNode;
  description: React.ReactNode;
  icon: React.ReactNode;
  onClick?: () => void;
}

const ListItem:React.FC<ListItemProps> = ({
  className = '', title, description, icon, ...props
}) => (
  <button type="button" className={cx('ListItem', className)} {...props}>
    <div className={cx('ListItem__Icon')}>
      {icon}
    </div>
    <div className={cx('ListItem__Title')}>
      {title}
    </div>
    <div className={cx('ListItem__Description')}>
      {description}
    </div>
  </button>
);

export default ListItem;
