/* node_modules */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

/* local imports */
import ContentBlur from '~/components/ContentBlur';
import Content from '../Content';

/* style imports */
import styles from './Collection.scss';

const cx = classNames.bind(styles);

interface Image {
  url: string;
  thumb: {
    url: string;
  };
}

interface ImageObject {
  images: Image[];
}

interface CollectionProps {
  image: ImageObject;
  blur: boolean;
  isPresentational?: boolean;
}

const Collection: React.FC<CollectionProps> = ({ image, isPresentational = false }) => {
  const [curImg, setCurImg] = useState(0);

  useEffect(() => {
    const handleKeyPress = (e) => {
      switch (e.keyCode) {
        case 37: // Left Arrow
          setCurImg((prev) => Math.max(0, prev - 1));
          break;
        case 39: // Right Arrow
          setCurImg((prev) => Math.min(image.images.length - 1, prev + 1));
          break;
        default:
          return;
      }
      e.stopPropagation();
      e.preventDefault();
    };


    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [image.images.length]); // Dependency for effect cleanup

  const go = (e, n) => {
    if (n < 0 || n > image.images.length - 1) return;
    setCurImg(n);
  };

  const { images } = image || {};

  return (
    <ContentBlur content={image}>
      <div className={styles.GalleryCollection}>
        {!isPresentational && (
          <span>
            {!(curImg === 0) && (
              <i
                className={cx('GalleryCollection__Controls', 'GalleryCollection__Controls--prev', 'ion-chevron-left')}
                onClick={(e) => go(e, curImg - 1)}
              />
            )}
            <p>{`${curImg + 1} / ${images?.length || 0}`}</p>
            {!(curImg === images?.length - 1) && (
              <i
                className={cx('GalleryCollection__Controls', 'GalleryCollection__Controls--next', 'ion-chevron-right')}
                onClick={(e) => go(e, curImg + 1)}
              />
            )}
          </span>
        )}
        <div className={styles.GalleryCollection__ThumbList}>
          {images?.map((img, i) => (
            <img
              key={`thumb:${i}`}
              onClick={(e) => go(e, i)}
              className={cx({
                GalleryCollection__Thumb: true,
                'GalleryCollection__Thumb--active': curImg === i,
              })}
              src={`${img.image[img.is_video || img.is_flash ? 'vthumb' : 'thumb'].url}`}
              loading="lazy"
              alt={img.image.description || `Image ${i + 1}`} // Optional alt text
            />
          ))}
        </div>
        <Content {...images?.[curImg]} />
      </div>
    </ContentBlur>
  );
};

export default Collection;
