import React, { useEffect, useRef } from 'react';

import styles from './Modal.scss';

interface Props {
  children: React.ReactNode;
  close?: null | (() => void);
  className?: string;
  title?: React.ReactNode;
}

const Modal:React.FC<Props> = ({
  children, title = '', close = null, className = '',
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const canClose = !!close;

  useEffect(() => {
    if (!canClose) return;

    function clickawayListener(e) {
      const box = ref.current;
      if (!box) return;

      if (!box.contains(e.target) && e.target !== box && close) close();
    }

    function escListener(e) {
      if (e.keyCode === 27 && close) close();
    }

    document.addEventListener('click', clickawayListener, true);
    document.addEventListener('keydown', escListener);

    return () => {
      document.removeEventListener('click', clickawayListener, true);
      document.removeEventListener('keydown', escListener);
    };
  }, [canClose, close]);

  return (
    <div ref={ref} className={`${styles.Modal} ${className}`}>
      <div className={styles.Modal__Title}>
        {title}
      </div>
      { canClose && <button className={styles.Modal__Close} aria-label="Close" type="button" onClick={close}><span role="img" className="ion-close-circled" /></button> }

      { children }
    </div>
  );
};

export default Modal;
