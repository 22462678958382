
import React, { useCallback, useContext, useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useIntl } from 'react-intl';

import { ChatContext, events } from './ChatContext';

import styles from './PendingRoomButton.scss';

const cx = classNames.bind(styles);

const failedMessage = {
  id: 'Chat_FailedToJoinRoom',
  defaultMessage: 'Failed to join the room, click to retry',
};

const joiningMessage = {
  id: 'Chat_JoiningRoom',
  defaultMessage: 'Joining room...',
};

const JOIN_TIMEOUT = 15000;

interface PendingRoomButtonProps {
  roomName: string;
  className?: string;
}

const PendingRoomButton:React.FC<PendingRoomButtonProps> = ({ className = '', roomName }) => {
  const { client } = useContext(ChatContext);
  const intl = useIntl();
  const [failed, setFailed] = useState(false);

  useEffect(() => {
    if (failed) {
      return;
    }

    const timeout = setTimeout(() => {
      setFailed(true);
    }, JOIN_TIMEOUT);

    return () => {
      clearTimeout(timeout);
    };
  }, [failed]);

  const attemptJoinRoom = useCallback(() => {
    setFailed(false);

    if (client) {
      client.emit(roomName === 'piczel-help' ? events.JOIN_ROOM : events.JOIN_STREAM, {
        [roomName === 'piczel-help' ? 'name' : 'username']: roomName,
      });
    }
  }, [client, roomName]);

  return (
    <button
      className={cx('PendingRoomButton', { 'PendingRoomButton--Failed': failed }, className)}
      type="button"
      disabled={!failed}
      onClick={attemptJoinRoom}
      title={intl.formatMessage(failed ? failedMessage : joiningMessage)}
    >
      {roomName}
      <span className={cx('PendingRoomButton__Icon')} role="img" />
    </button>
  );
};

export default PendingRoomButton;
