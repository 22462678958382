import React from 'react';
import styles from './HideToggle.scss';

interface HideToggleProps {
  direction: string;
  className?: string;
  onHide: () => void;
}

export default function HideToggle({ direction, className = '', onHide }: HideToggleProps) {
  return (
    <button
      className={`${styles.HideToggle} ${className || ''} ${styles[`toggle-${direction}`]}`}
      onClick={onHide}
      type="button"
      aria-label="Toggle stream description panel"
      title="Toggle stream description panel"
    />
  );
}
